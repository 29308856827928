<template>
  <label class="checkbox-inline" for="tokenList" v-if="inline">
    {{ $tc("access_key", 1) }} ({{ $t("username") }})
    <div class="form-group form-group-sm">
      <input
        type="text"
        class="form-control"
        v-if="!(tokenList || []).length"
        disabled
      />
      <select
        v-else
        name="tokenList"
        id="tokenList"
        class="form-control form-control-inline"
        v-model="selectedToken"
      >
        <option
          v-for="item in tokenList"
          :key="item.id"
          :value="item"
          :title="item.description"
        >
          {{ item.token }}
        </option>
      </select>
      <span
        class="btn btn-default btn-sm"
        style="border-left:0;margin-top:-2px;"
        @click.stop.prevent="token && copyToClipboard(token)"
      >
        <i class="fa fa-copy"></i>
      </span>
      <slot name="addonButton"></slot>
    </div>
  </label>
  <div class="form-group" v-else>
    <label for="tokenList">
      {{ $t(label) }}
    </label>
    <div class="input-group">
      <div class="input-group-addon">
        {{ $tc("access_key", 1) }}
      </div>
      <input
        type="text"
        class="form-control"
        v-if="!(tokenList || []).length"
        disabled
      />
      <select
        v-else
        name="tokenList"
        id="tokenList"
        class="form-control text-center"
        v-model="selectedToken"
      >
        <option
          v-for="item in tokenList"
          :key="item.id"
          :value="item"
          :title="item.description"
        >
          {{ item.token }}
        </option>
      </select>
      <div
        class="input-group-addon btn"
        @click.stop.prevent="token && copyToClipboard(token)"
      >
        <i class="fa fa-copy"></i>
      </div>
    </div>
  </div>
</template>

<script>
import TokenService from "@/services/token.js";
export default {
  name: "MqttAccessTokenSelector",
  props: {
    connector: {
      type: Object,
      required: false,
      default: () => null
    },
    label: {
      type: String,
      required: false,
      default: "access_token"
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedToken: null,
      tokenList: []
    };
  },
  computed: {
    token() {
      return this?.selectedToken?.token ?? "";
    }
  },
  watch: {
    token(n) {
      this.$emit("changed", n);
    }
  },
  methods: {
    copyToClipboard(text) {
      this.$utils.copyToClipboard(this, text);
    },
    init() {
      this.tokenService
        .fetch({
          contract_id: this.$store.getters["user/contract"]?.id
        })
        .then((resp) => {
          this.$set(
            this,
            "tokenList",
            [...(resp || [])].filter(({enabled, allowed_connectors}) => {
              if (!enabled) return false;
              if ((allowed_connectors || []).length) {
                return allowed_connectors.some(
                  ({id}) => parseInt(id) === parseInt(this.connector.id)
                );
              }
              return true;
            })
          );
          if (this.tokenList.length) {
            this.selectedToken = this.tokenList[0];
          }
        });
    }
  },
  beforeCreate() {
    this.tokenService = new TokenService("access_token");
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.tokenService = null;
  }
};
</script>

<style scoped>
form.inline-form,
select.form-control.form-control-inline,
form.inline-form,
input.form-control {
  min-width: 200px;
  margin-left: 10px;
  width: calc(100% - 30px);
  display: inline-block;
}
form.inline-form,
div.radio {
  padding-top: 8px;
}

label.checkbox-inline {
  margin-right: 10px;
  white-space: nowrap;
}
label > .form-group {
  display: inline-block;
  margin-bottom: 0;
  padding: 0 30px 0 0;
}
</style>
